@import "../../styles/variables.scss";

.footer_text {
  margin-top: 10px;
  color: #eee;
  font-size: 15px;
  font-weight: 500;
}

.small_footer_text {
  color: #ccc;
  font-size: 13px;
}

.footer_links_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;

  .footer_links_col {
    margin-bottom: 30px;

    .footer_link_heading {
      color: #ccc;
      font-size: 13px;
      line-height: 32px;
      font-weight: 700;
      margin-bottom: 24px;
      text-transform: uppercase;
    }

    ul {
      padding: 0;

      li {
        margin-bottom: 16px;

        a {
          color: white;
          font-size: 15px;
          line-height: 26px;
          cursor: pointer;

          &:hover {
            transition: color 0.1s linear;
            font-weight: 500;
            color: #24a9a7;
          }
        }
      }
    }
  }
}

.footer_social {
  @media not screen and #{$mq-mobile} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer_social_links {
    display: flex;

    @media screen and #{$mq-mobile} {
      margin-top: 15px;
    }

    a {
      color: #999;
      display: inline-block;
      padding-right: 15px;
      font-size: 20px;

      &:hover {
        color: #24a9a7;
      }
    }
  }
}
